import {BackButton, SendButton, onSubmitFailFocusFieldWithError} from '@eon.cz/apollo13-frontend-common';
import {yupResolver} from '@hookform/resolvers/yup';
import {FormHelperText} from '@mui/material';
import Grid from '@mui/material/Grid2';
import {FormProvider, useForm} from 'react-hook-form';
import {FormattedMessage} from 'react-intl';
import {Lang} from '../../../../Lang';
import {FormInput} from '../../../Common/components/form/FormInput';
import {CreateResetHeslaPozadavekModel} from '../../model/CreateResetHeslaPozadavekModel';
import {validationSchemaResethesla} from '../../service/AuthValidation';

type Props = {
    showResetSubmitted: boolean;
    onClickBack: () => void;
    onSubmit: (model: CreateResetHeslaPozadavekModel) => void;
};

/**
 * Formulář na reset hesla
 */
export const CreateResetHeslaPozadavekForm = ({showResetSubmitted, onSubmit, onClickBack}: Props) => {
    const methods = useForm<CreateResetHeslaPozadavekModel>({
        resolver: yupResolver(validationSchemaResethesla),
        mode: 'onChange',
    });

    const {handleSubmit} = methods;

    return (
        <FormProvider {...methods}>
            <form onSubmit={handleSubmit(onSubmit, onSubmitFailFocusFieldWithError)} noValidate>
                <Grid container spacing={1}>
                    <Grid size={{xs: 12}}>
                        <FormInput name="email" type="text" label={Lang.UCET_EMAIL} autofocus trimSpace />
                        <FormHelperText>
                            <FormattedMessage id={Lang.RESET_HESLA_POZADAVEK_NAPOVEDA} />
                        </FormHelperText>
                    </Grid>

                    <Grid size={{xs: 12, sm: 6}}>
                        <BackButton variant="contained" caption={Lang.LOGIN_ZPET_NA_PRIHLASENI} fullWidth onClick={onClickBack} />
                    </Grid>
                    {!showResetSubmitted && (
                        <Grid size={{xs: 12, sm: 6}}>
                            <SendButton variant="contained" fullWidth type="submit" />
                        </Grid>
                    )}
                </Grid>
            </form>
        </FormProvider>
    );
};
