import {PasswordQuality} from '@eon.cz/apollo13-graphql';
import yup, {emailValidation} from '../../Common/services/YupLocale';

const string = yup.string();
export const validationSchemaResethesla = yup.object().shape({
    email: emailValidation.required(),
});

export const getCharacterValidationError = (value: string) => {
    let message = 'Heslo musí obsahovat minimálně 1 velké písmeno, 1 malé písmeno, 1 číslici.';
    let complexityMessage = undefined;
    let error = true;
    let score = PasswordQuality.SLABE;
    const twoSpecialsReg = /(?=(.*[.*\-_@#&\/$+,.!? ]){2})/;
    const upperCase = value?.match(/[A-ZÁ-Ž]/);
    const lowerCase = value?.match(/[a-ž]/);
    const number = value?.match(/[0-9]/);
    const baseCondition = upperCase && lowerCase && number;

    if (upperCase) {
        message = message.replace('1 velké písmeno,', '');
        error = false;
    }
    if (lowerCase) {
        message = message.replace('1 malé písmeno,', '');
        error = false;
    }
    if (number) {
        message = message.replace('1 číslici', '');
        error = false;
    }
    if (baseCondition) {
        message = '';
        error = true;
    }
    if (value?.length < 8) {
        message = 'Heslo musí mít alespoň 8 znaků.';
        error = false;
    }
    if (value?.length > 30) {
        message = 'Heslo může mít maximálně 30 znaků.';
        error = false;
    }
    if (baseCondition && !twoSpecialsReg.test(value)) {
        complexityMessage = 'Pro silnější zabezpečení hesla doporučujeme využít alespoň 12 znaků, minimálně 2 speciální znaky.';
        score = PasswordQuality.SLABE;
    }
    if (baseCondition && value?.length >= 12 && !twoSpecialsReg.test(value)) {
        complexityMessage = 'Pro silnější zabezpečení hesla doporučujeme využít minimálně 2 speciální znaky.';
        score = PasswordQuality.STREDNE_SILNE;
    }
    if (baseCondition && value?.length >= 12 && twoSpecialsReg.test(value)) {
        complexityMessage = undefined;
        score = PasswordQuality.SILNE;
    }
    if (baseCondition && value?.length < 12 && twoSpecialsReg.test(value)) {
        complexityMessage = 'Pro silnější zabezpečení hesla doporučujeme využít alespoň 12 znaků.';
        score = PasswordQuality.STREDNE_SILNE;
    }

    return {message, error, complexityMessage, score};
};
export const validationSchemaReset = yup.object().shape({
    heslo: string
        .test(
            '',
            ({value}) => getCharacterValidationError(value).message,
            (value) => {
                if (value) {
                    return getCharacterValidationError(value).error;
                }
                return true;
            },
        )
        .min(8)
        .max(30),

    hesloZnovu: string.test(
        '',
        () => 'Zadaná hesla se neshodují.',
        (value, ctx) => {
            if (value) {
                return value === ctx.parent.heslo;
            }
            return true;
        },
    ),
});
