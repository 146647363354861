import {Div} from '@eon.cz/apollo13-frontend-common';
import {Typography} from '@mui/material';
import {ReactNode} from 'react';
import {FormattedMessage} from 'react-intl';
import {Lang} from '../../../../Lang';
import {useMatches} from '../../services/PageService';
import {LandingBackground} from './LandingBackground';

type Props = {
    children: ReactNode;
    login?: boolean;
};

/**
 * Wrapper komponenta pro přihlášení a reset hesla
 */
export const LandingLayout = ({children, login}: Props) => {
    const matches = useMatches();

    return (
        <LandingBackground login={login}>
            {login ? (
                <Div
                    sx={{
                        backgroundColor: 'background.default',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        borderRadius: 10,
                        margin: 1,
                        padding: 1,
                    }}
                >
                    <Typography
                        sx={{
                            textAlign: 'center',
                            padding: 1,
                            fontWeight: 'bold',
                            fontSize: !matches ? 68 : 48,
                        }}
                    >
                        <FormattedMessage id={Lang.PORTAL} />
                    </Typography>

                    {children}
                </Div>
            ) : (
                children
            )}
        </LandingBackground>
    );
};
