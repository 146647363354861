import {BackButton} from '@eon.cz/apollo13-frontend-common';
import {Typography} from '@mui/material';
import Grid from '@mui/material/Grid2';
import axios from 'axios';
import {useState} from 'react';
import {FormattedMessage} from 'react-intl';
import {BackendEndpoints} from '../../../../server/BackendEndpoints';
import {Lang} from '../../../Lang';
import {CreateResetHeslaPozadavekForm} from '../components/resetHesla/CreateResetHeslaPozadavekForm';
import {CreateResetHeslaPozadavekModel} from '../model/CreateResetHeslaPozadavekModel';

type Props = {
    showResetSubmitted: boolean;
    onClickBack: () => void;
    onSubmitDone: () => void;
};

/**
 * Komponenta na poslání požadavku na reset hesla
 */
export const CreateResetHeslaPozadavekContainer = ({showResetSubmitted, onClickBack, onSubmitDone}: Props) => {
    const [submittedEmail, setSubmittedEmail] = useState('');

    const handleSubmitResetHesla = ({email}: CreateResetHeslaPozadavekModel) => {
        setSubmittedEmail(email);
        axios.post(`${window.location.origin}/api/${BackendEndpoints.CREATE_RESET_HESLA_POZADAVEK}`, {email}).then(() => onSubmitDone());
    };

    return (
        <Grid
            container
            sx={{
                padding: 1,
                textAlign: 'center',
                width: {xs: '100%', sm: 430},
                margin: 'auto',
            }}
        >
            <Grid size={{xs: 12}}>
                <Typography variant="h6" sx={{textAlign: 'center'}}>
                    <FormattedMessage id={Lang.RESET_HESLA_POZADAVEK_TITLE} />
                </Typography>
            </Grid>
            {showResetSubmitted ? (
                <>
                    <Grid size={{xs: 12}}>
                        <Typography variant="subtitle1">
                            <FormattedMessage id={Lang.RESET_HESLA_POZADAVEK_ODESLANO} />
                        </Typography>
                        <Typography variant="subtitle2">
                            <FormattedMessage
                                id={Lang.RESET_HESLA_POZADAVEK_ODESLANO_TEXT}
                                values={{
                                    email: submittedEmail,
                                }}
                            />
                        </Typography>
                        <Typography variant="subtitle2">
                            <FormattedMessage id={Lang.RESET_HESLA_POZADAVEK_ODESLANO_UPOZORNENI} />
                        </Typography>
                    </Grid>
                    <Grid size={{xs: 12}}>
                        <BackButton variant="contained" caption={Lang.LOGIN_ZPET_NA_PRIHLASENI} onClick={onClickBack} />
                    </Grid>
                </>
            ) : (
                <Grid size={{xs: 12}}>
                    <CreateResetHeslaPozadavekForm showResetSubmitted={showResetSubmitted} onClickBack={onClickBack} onSubmit={handleSubmitResetHesla} />
                </Grid>
            )}
        </Grid>
    );
};
